import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { AccountRequirement } from '@models/events/account-requirement.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { Event } from '@models/events/event.model';
import { OrderOverviewComponent } from '@modules/events/components/event-payment/components/order-overview/order-overview.component';
import { DateCardComponent } from '@modules/shared/components/date-card/date-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { NumberUtils } from '@utils/number-utils';
import { FooterComponent } from 'app/components/footer/footer.component';

@Component({
  selector: 'app-ticket-payment-success',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OrderOverviewComponent,
    DateCardComponent,
    FooterComponent,
  ],
  providers: [EventService, NotificationService, ScreenWidthService],
  templateUrl: './ticket-payment-success.dialog.html',
  styleUrl: './ticket-payment-success.dialog.scss',
})
export class TicketPaymentSuccessDialog {
  ticketOrder?: EventAttendeeTicketOrder;
  mainImage?: string;
  event?: Event;

  isDesktop?: boolean;
  isGeneric?: boolean = true;

  currentLang?: string;

  constructor(
    public dialogRef: MatDialogRef<TicketPaymentSuccessDialog>,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.ticketOrder = data.ticketOrder;
      this.mainImage = data.mainImage;
      this.event = data.event;
    }

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });

    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });
    this.currentLang = this.translateService.currentLang;
  }

  downloadTicketPdf() {
    if (!this.ticketOrder) {
      return;
    }

    let fileName = `${this.ticketOrder.getDateDisplayFileName()}_${
      this.ticketOrder.orderNumber
    }.pdf`;
    if (this.event) {
      fileName = `${this.ticketOrder.getDateDisplayFileName()}_${this.event.getSanitizedName()}_${
        this.ticketOrder.orderNumber
      }.pdf`;
    }

    this.eventService
      .downloadTicketOrderPdf(this.ticketOrder.uuid, fileName)
      .subscribe({
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  addToWallet() {
    if (!this.ticketOrder || this.ticketOrder.isProcessing()) {
      return;
    }

    this.eventService
      .getEventTicketOrderWalletPassBundle(this.ticketOrder.uuid)
      .subscribe({
        next: (passPageLink: string) => {
          window.open(passPageLink, '_blank');
        },
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  close() {
    this.dialogRef.close();
  }

  shouldShowTicketManagementInfo(): boolean {
    const tickets = this.ticketOrder?.eventAttendeeTickets;
    const hasMultiple = this.ticketOrder?.hasMultipleTickets();

    return Boolean(tickets?.length &&
      tickets[0]?.eventTicket?.name !== 'Schulaktion' &&
      hasMultiple);
  }

  protected readonly NumberUtils = NumberUtils;
  protected readonly AccountRequirement = AccountRequirement;
}
